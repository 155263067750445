@tailwind base;

/* Custom Font */
@font-face {
    font-family: 'gerstner_programm_fsllight';
    src: url('/font/gerstnerprogrammfsl/gerstnerprogrammfsl-light-webfont.woff2')
            format('woff2'),
        url('/font/gerstnerprogrammfsl/gerstnerprogrammfsl-light-webfont.woff')
            format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gerstner_programm_fslmedium';
    src: url('/font/gerstnerprogrammfsl/gerstnerprogrammfsl-medium-webfont.woff2')
            format('woff2'),
        url('/font/gerstnerprogrammfsl/gerstnerprogrammfsl-medium-webfont.woff')
            format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'bpm_gravityextra_condensed';
    src: url('/font/bpmgravity/bpmgravity-extracondensed-webfont.woff2')
            format('woff2'),
        url('/font/bpmgravity/bpmgravity-extracondensed-webfont.woff')
            format('woff');
    font-weight: normal;
    font-style: normal;
}
/***************/

:root {
    font-size: 62.5%;
    --color-cod-gray-300: #1a1a1a;
    --color-gray-200: #919191;
}

@media only screen and (min-width: 770px) and (max-width: 1140px) {
    :root {
    }
    .logo-desk {
        transform: scale(0.85);
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px black inset !important;
    background-clip: content-box !important;
    background-color: transparent !important;
    -webkit-text-fill-color: #e6f0e9;
    caret-color: #e6f0e9;
}

* {
    /* -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none; */
}

input,
textarea {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

html {
    background: black;
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
}

body {
    font-size: 1.6rem;
    font-weight: 500;
    overscroll-behavior-y: none;
}

a {
    transition: all 0.15s ease-in-out;
    @apply text-bpm-white;
}

a:hover {
    @apply opacity-80;
}

@tailwind components;

@tailwind utilities;

.content p {
    @apply my-6;
}

.content ul {
    @apply my-6;
}

.faqAccordion header img,
.mobile-footer header img {
    transition: all 0.3s ease-in-out;
    transform: scale(0.5) rotate(270deg);
}

.faqAccordion header.open img,
.mobile-footer header.open img {
    transform: rotate(90deg) scale(0.5);
}

/*@media (orientation: landscape) and (hover: none) and (pointer: coarse) {*/
/*}*/

.grecaptcha-badge {
    display: none !important;
}

.changeSvgOnHover svg path,
.changeSvgOnHover svg {
    transition: all 0.3s ease-in-out;
}

.changeSvgOnHover:hover svg path {
    stroke: black;
}
